<template>
    <div    class="rank-drop-item" 
            ref="itemRef" 
            :style="{
                'height': itemFixedHeight ? `${itemFixedHeight}px` : null
            }">
        <div class="rdi-num">
            {{ (dataId + 1) }}
        </div>
        <div    class="rdi-lbl"
                :style="{
                    flex: itemFixedHeight ? 1 : null
                }">
                <div v-html="text"
                    :style="{
                        'overflow': itemFixedHeight ? 'hidden' : null,
                        'text-overflow': itemFixedHeight ? 'ellipsis' : null,
                        'white-space': itemFixedHeight ? 'nowrap': null,
                        'display': 'block',
                        'width': '100%',
                    }"></div>
        </div>
        <button class="rdi-btn addItem"  v-show="$parent.$parent?.mode == 0" @click="_moveItemChosen()"> + </button>
        <button class="rdi-btn removeItem" v-show="$parent.$parent?.mode == 0" @click="_moveItemSource()"> x </button>
        <!-- <div class="item-drag-handler">
            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z" fill="#888"/></svg>
        </div> -->
    </div>
</template>

<script>
    export default {
        props: {
            text: String,
            dataId: Number,
            itemData: Object,
            itemFixedHeight: Number
        },
        mounted(){
            /**
             * INJECT DATA TO THE DOM OBJECT FOR EASY DATA RETRIEVAL
             */
            this.$refs.itemRef['itemData'] = this.itemData
        },
        methods:{
            _moveItemChosen(){
              
               let mainParent = this.$parent.$parent
               let d1 = document.querySelector(`[tag="${mainParent.data?.TAG}"] .drop-zone[data-role="chosen"]`)
               let d2 = document.querySelector(`[tag="${mainParent.data?.TAG}"] .drop-zone[data-role="sources"]`)
               if(mainParent.limitCount > 0){
                    if(d1.childElementCount >= mainParent.limitCount){
                        return false;
                    }
                }

               let RankItem = this.$refs.itemRef;
               d1.appendChild(RankItem);
               let chosenIndex = 1;
               d1.querySelectorAll('.rank-drop-item .rdi-num').forEach((itm) => {
                console.log(itm)
                    if(itm.parentNode.className.indexOf('sortable-drag') === -1){
                        itm.textContent = chosenIndex;
                        chosenIndex++;
                    }
               })

               if(d2){
                    let notChosenIndex = 1;
                    d2.querySelectorAll('.rank-drop-item .rdi-num').forEach((itm) => {
                        if(itm.parentNode.className.indexOf('sortable-drag') === -1){
                            itm.textContent = notChosenIndex;
                            notChosenIndex++;
                        }
                    });
                }

            },
            _moveItemSource(){
             
               let mainParent = this.$parent.$parent
               let d1 = document.querySelector(`[tag="${mainParent.data?.TAG}"] .drop-zone[data-role="chosen"]`)
               let d2 = document.querySelector(`[tag="${mainParent.data?.TAG}"] .drop-zone[data-role="sources"]`)
               let RankItem = this.$refs.itemRef;
               d2.appendChild(RankItem);
               let chosenIndex = 1;
               d2.querySelectorAll('.rank-drop-item .rdi-num').forEach((itm) => {
                console.log(itm)
                    if(itm.parentNode.className.indexOf('sortable-drag') === -1){
                        itm.textContent = chosenIndex;
                        chosenIndex++;
                    }
               })

               if(d1){
                    let notChosenIndex = 1;
                    d1.querySelectorAll('.rank-drop-item .rdi-num').forEach((itm) => {
                        if(itm.parentNode.className.indexOf('sortable-drag') === -1){
                            itm.textContent = notChosenIndex;
                            notChosenIndex++;
                        }
                    });
                }                
            }
        }
    }
</script>
